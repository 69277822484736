<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="getShuffledUsersData()"
          hover
          striped
          border
          small
          fixed
          caption=""
        />  
      </CCol>          
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from './farmerData/FarmerTable.vue'
import farmerData from './farmerData/FarmerData'

export default {
  name: 'FarmerData',
  components: { CTableWrapper },
  methods: {
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },
    getShuffledUsersData () {
      return this.shuffleArray(farmerData.slice(0))
    },
  },
  
}
</script>
