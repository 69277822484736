<template>
<div>
     <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>

    <div>
        <div>
            <CCard>
                <CRow class="mt-4 ml-4">
                    <div class="mt-1 mr-3">
                        <CSelect
                            placeholder="File Type"
                            :options="[
                                { label: 'Sensor data', value: '1' },
                                {
                                    label: 'Soil-chemical parameter data',
                                    value: '2',
                                },
                            ]"
                            v-model="fileID"
                        />
                    </div>
                    <div class="mt-1 mr-3">
                        <input
                            id="fileUpload"
                            type="file"
                            @change="loadFile"
                            hidden
                        />
                        <CButton
                            v-if="file == null"
                            size="sm"
                            color="primary"
                            @click="chooseFile"
                        >
                            <CIcon name="cil-cloud-upload" />
                            Upload File
                        </CButton>
                        <CButton
                            v-else
                            size="sm"
                            class="save-button"
                            color="primary"
                            @click="saveFile"
                        >
                            <CIcon name="cil-cloud-upload" />
                            Save File
                        </CButton>
                    </div>
                </CRow>

                <CCardBody> 
                    <CDataTable
                        :hover="true"
                        :striped="true"
                        :border="true"
                        :small="small"
                        :fixed="true"
                        :items="historyItems"
                        :fields="fields"
                        :items-per-page="small ? 5 : 5"
                        :clickable-rows="true"
                        :sorter="{ external: true, resetable: true }"
                        pagination
                    >
                        <template #Action1="{ item }">
                            <td class="d-flex">
                                <CButton
                                    color="danger"
                                    size="sm"
                                    class="mr-2 mb-2"
                                    :id="item.id"
                                    @click="openModal(item, 1)"
                                    ><CIcon name="cil-trash"
                                /></CButton>
                            </td>
                        </template>

                        <template #Action2="{ item }">
                            <td class="d-flex">
                                <CButton
                                    color="danger"
                                    size="sm"
                                    class="mr-2 mb-2"
                                    :id="item.id"
                                    @click="openModal(item, 2)"
                                    ><CIcon name="cil-trash"
                                /></CButton>
                            </td>
                        </template>
                    </CDataTable>
                    <CModal
                        title="Delete CSV File"
                        :show.sync="myModal"
                        size="sm"
                    >
                        Are you sure you want to proceed?
                        <template #footer>
                            <CButton @click="myModal = false" color="danger"
                                >Cancel</CButton
                            >
                            <CButton @click="deleteFile()" color="success"
                                >Delete</CButton
                            >
                        </template>
                    </CModal>
                </CCardBody>
            </CCard>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import jwt_decode from "jwt-decode";

const URL = axios.defaults.baseURL;
const csvUploadHistoryUrl = URL + `${"profile/csvupload/history/"}`;
const farmerCSVUrl = URL + `${"profile/csvupload/"}`;

export default {
    name: "FarmerTable",
    data() {
        return {
            errorMessage: "",
            checkError: false,
            fetch: false,
            file: null,
            file2: null,
            fileID: null,
            myModal: false,
            deleteElement: null,
            historyItems: [],

            fields: [
                {
                    key: "name",
                    label: "Sensor data",
                },
                {
                    key: "type",
                    label: "Data type",
                },

                {
                    key: "uploaded_at",
                    label: "Last updated",
                },
                {
                    key: "Action1",
                    label: "Action",
                },
                {
                    key: "name2",
                    label: "Soil-chemical parameter data",
                },
                {
                    key: "type2",
                    label: "Data type",
                },

                {
                    key: "uploaded_at2",
                    label: "Last updated",
                },
                {
                    key: "Action2",
                    label: "Action",
                },
            ],
        };
    },
    methods: {
        openModal(item, id) {
            this.deleteElement = item;
            this.myModal = true;
            this.fileID = id;
        },
        getUploadHistory() {
            var id = jwt_decode(localStorage.getItem("token")).id;

            this.axios
                .get(csvUploadHistoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: id },
                })
                .then((response) => {
                    this.fetch = true;
                    console.log(response.data.context);
                    this.historyItems = response.data.context;
                })
                .catch((error) => {
                    this.fetch = false;
                    this.checkError = true;
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
        chooseFile() {
            document.getElementById("fileUpload").click();
        },
        loadFile(event) {
            this.file = event.target.files[0];
        },
        saveFile() {
            let form_data = new FormData();
            if (this.fileID == 1) {
                if (this.file != null) {
                    form_data.append("csv_file1", this.file);
                }
            }
            if (this.fileID == 2) {
                if (this.file != null) {
                    form_data.append("csv_file2", this.file);
                }
            }
            this.file = null;
            this.saveUserData(form_data);
        },
        saveUserData(form_data) {
            this.axios
                .put(farmerCSVUrl, form_data, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.fileID },
                })
                .then((response) => {
                    console.log(response.data.message);
                    document.getElementById("fileUpload").value = null;
                    localStorage.removeItem("token");
                    localStorage.setItem("token", response.data.access_token);
                    this.getUploadHistory();
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
        deleteFile() {
            this.axios
                .delete(farmerCSVUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.fileID },
                })
                .then((response) => {
                    this.myModal = false;
                    this.deleteElement = null;
                    localStorage.removeItem("token");
                    localStorage.setItem("token", response.data.access_token);
                    this.getUploadHistory();
                })
                .catch((error) => {
                    this.myModal = false; 
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
    },

    props: {
        small: Boolean,
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },

    beforeMount() {
        this.getUploadHistory();
    },
};
</script>
