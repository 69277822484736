var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('CAlert',{staticClass:"alert-dismissible",attrs:{"color":"danger","show":_vm.checkError},on:{"update:show":function($event){_vm.checkError=$event}}},[_vm._v(" "+_vm._s(this.errorMessage)+" ")])],1),_c('div',[_c('div',[_c('CCard',[_c('CRow',{staticClass:"mt-4 ml-4"},[_c('div',{staticClass:"mt-1 mr-3"},[_c('CSelect',{attrs:{"placeholder":"File Type","options":[
                                { label: 'Sensor data', value: '1' },
                                {
                                    label: 'Soil-chemical parameter data',
                                    value: '2',
                                } ]},model:{value:(_vm.fileID),callback:function ($$v) {_vm.fileID=$$v},expression:"fileID"}})],1),_c('div',{staticClass:"mt-1 mr-3"},[_c('input',{attrs:{"id":"fileUpload","type":"file","hidden":""},on:{"change":_vm.loadFile}}),(_vm.file == null)?_c('CButton',{attrs:{"size":"sm","color":"primary"},on:{"click":_vm.chooseFile}},[_c('CIcon',{attrs:{"name":"cil-cloud-upload"}}),_vm._v(" Upload File ")],1):_c('CButton',{staticClass:"save-button",attrs:{"size":"sm","color":"primary"},on:{"click":_vm.saveFile}},[_c('CIcon',{attrs:{"name":"cil-cloud-upload"}}),_vm._v(" Save File ")],1)],1)]),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":true,"striped":true,"border":true,"small":_vm.small,"fixed":true,"items":_vm.historyItems,"fields":_vm.fields,"items-per-page":_vm.small ? 5 : 5,"clickable-rows":true,"sorter":{ external: true, resetable: true },"pagination":""},scopedSlots:_vm._u([{key:"Action1",fn:function(ref){
                            var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"danger","size":"sm","id":item.id},on:{"click":function($event){return _vm.openModal(item, 1)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}},{key:"Action2",fn:function(ref){
                            var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"danger","size":"sm","id":item.id},on:{"click":function($event){return _vm.openModal(item, 2)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])}),_c('CModal',{attrs:{"title":"Delete CSV File","show":_vm.myModal,"size":"sm"},on:{"update:show":function($event){_vm.myModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.myModal = false}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.deleteFile()}}},[_vm._v("Delete")])]},proxy:true}])},[_vm._v(" Are you sure you want to proceed? ")])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }